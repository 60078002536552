import React from "react";
import { FontAwesome5 } from '@expo/vector-icons';

import {
  Input,
  Button,
  FormControl,
  Select,
  Icon,
  VStack,
  Text,
  Box,
  Popover,
  ScrollView
} from 'native-base';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { fundosController } from "../controllers/FundosController";
import { fornecedoresController } from "../controllers/FornecedoresController";
import SearchInput from "../components/SearchInput";
import MaskedInput from "../components/MaskedInput";
import { constsController } from "../controllers/ConstsController";
import { ResponsiveComponentSelector, ResponsiveStack } from "../components/ResponsiveUtils";
import CurrencyInput from "../components/CurrencyInput";

class SearchPanelBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedText: '',
      solicitacaoId: 0,
      fundoId: 0,
      fundo: '',
      fornecedorId: 0,
      fornecedor: '',
      startDate: '',
      finalDate: '',
      status: -1,
      tipoItensSolicitacao: -1,
      invalidInputs: [],
      valor: 0,
      tipoSolicitacao: -1
    };
    const filtroStr = localStorage.getItem('filtro');
    if (filtroStr) {
      const options = JSON.parse(filtroStr);
      if (options) {
        this.state.searchedText = options.searchedText ? options.searchedText : '';
        this.state.solicitacaoId = options.solicitacaoId ? options.solicitacaoId : 0;
        this.state.fornecedorId = options.fornecedorId ? options.fornecedorId : 0;
        this.state.fornecedor = options.fornecedor ? options.fornecedor : '';
        this.state.fundoId = options.fundoId ? options.fundoId : 0;
        this.state.fundo = options.fundo ? options.fundo : '';
        this.state.startDate = options.startDate ? options.startDate : '';
        this.state.finalDate = options.finalDate ? options.finalDate : '';
        this.state.status = options.status ? options.status : -1;
        this.state.tipoItensSolicitacao = options.tipoItensSolicitacao ? options.tipoItensSolicitacao : -1;
        this.state.valor =  options.valor ? options.valor : 0;
        this.state.tipoSolicitacao =  options.tipoSolicitacao ? options.tipoSolicitacao : -1;
      }
    }
    this.timeoutID = -1;
  }

  render() {
    return (
      <Box {...this.props}>


        <ResponsiveStack space={1} flex={1} height={40}>

          <FormControl flex={1}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Tipo</FormControl.Label>
            <Select
              defaultValue={this.state.tipoItensSolicitacao?.toString() ?? ''}
              onValueChange={(selectedItem) => this.setState({ tipoItensSolicitacao: parseInt(selectedItem) }, this.#onSearch)}
              placeholder="Escolher tipo"
              key={'tipoItensSolicitacao' + this.state.tipoItensSolicitacao}
            >
              <Select.Item label={'Todos'} value={'-1'} key={'-1'} />
              {
                Object.entries(constsController.tipoItensSolicitados).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
          </FormControl>

          <FormControl flex={1}>
            <FormControl.Label marginBottom="0">Texto</FormControl.Label>
            <Input defaultValue={this.state.searchedText} onChangeText={(text) => this.setState({ searchedText: text }, this.#onSearch)} />
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('solicitacaoId') !== -1}>
            <FormControl.Label marginBottom="0">Nº Solicitação</FormControl.Label>
            <MaskedInput
              mask="999999999"
              value={this.state.solicitacaoId > 0 ? this.state.solicitacaoId : ''}
              onChangeMaskedText={(text) => this.setState({ solicitacaoId: parseInt(text) }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Nº Solicitação inválido</FormControl.ErrorMessage>
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('valor') !== -1}>
            <FormControl.Label marginBottom="0">Valor da Solicitação</FormControl.Label>
            <CurrencyInput
                  value={this.state.valor}
                  onChangeValue={(newValue) => this.setState({ valor: newValue }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Valor inválido</FormControl.ErrorMessage>
          </FormControl>

          <FormControl flex={1}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Status</FormControl.Label>
            <Select
              defaultValue={this.state.status?.toString() ?? ''}
              onValueChange={(selectedItem) => this.setState({ status: parseInt(selectedItem) }, this.#onSearch)}
              placeholder="Escolher status"
              key={'status' + this.state.status}
            >
              <Select.Item label={'Todos'} value={'-1'} key={'-1'} />
              {
                Object.entries(constsController.statusSolicitacao).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
          </FormControl>

          <SearchInput
            flex={1}
            label="Fundo"
            defaultValue={this.state.fundo}
            onSearchItems={fundosController.searchFundos}
            onItemSelect={(item) => this.setState({ fundoId: item.id, fundo: item.nome }, this.#onSearch)}
            placeholder="Pesquisar fundos "
            key={'fundo' + this.state.fundoId}
          />

        </ResponsiveStack>
        <ResponsiveStack space={1} flex={1} height={40}>
          <SearchInput

            flex={1}
            label="Fornecedor"
            defaultValue={this.state.fornecedor}
            onSearchItems={this.#searchFornecedores}
            onItemSelect={(item) => this.setState({ fornecedorId: item.id, fornecedor: item.nome }, this.#onSearch)}
            placeholder="Pesquisar fornecedores"
            key={'fornecedor' + this.state.fornecedorId}
          />

          <FormControl flex={1}>
            <FormControl.Label _text={{ isTruncated: true }} marginBottom="0">Modalidade</FormControl.Label>
            <Select
              defaultValue={this.state.tipoSolicitacao?.toString() ?? ''}
              onValueChange={(selectedItem) => this.setState({ tipoSolicitacao: parseInt(selectedItem) }, this.#onSearch)}
              placeholder="Escolher Modalidade"
              key={'modalidade' + this.state.tipoSolicitacao}
            >
              <Select.Item label={'Todos'} value={'-1'} key={'-1'} />
              {
                Object.entries(constsController.tiposSolicitacao).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('startDate') !== -1}>
            <FormControl.Label marginBottom="0">Data inicial</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              value={this.state.startDate}
              onChangeMaskedText={(text) => this.setState({ startDate: text }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Data inválida</FormControl.ErrorMessage>
          </FormControl>

          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('finalDate') !== -1}>
            <FormControl.Label marginBottom="0">Data final</FormControl.Label>
            <MaskedInput
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              value={this.state.finalDate}
              onChangeMaskedText={(text) => this.setState({ finalDate: text }, this.#onSearch)}
            />
            <FormControl.ErrorMessage marginTop="0">Data inválida</FormControl.ErrorMessage>
          </FormControl>

          <VStack flex={1}>
            <Text>   </Text>
            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FontAwesome5} name="search" size="4" />}
              onPress={this.#onSearch}
            >
              Buscar
            </Button>
          </VStack>

          <VStack flex={1}>
            <Text>   </Text>
            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FontAwesome5} name="download" size="4" />}
              onPress={this.#onDownload}
            >
              Baixar
            </Button>
          </VStack>
        </ResponsiveStack>
      </Box>
    );
  }

  #getFilter = () => {
    const invalidInputs = [];
    if (this.state.startDate)
      if (this.state.startDate.length > 0 && !dayjs(this.state.startDate, 'DD/MM/YYYY', true).isValid()) {
        invalidInputs.push('startDate');
      } if (this.state.finalDate)
      if (this.state.finalDate.length > 0 && !dayjs(this.state.finalDate, 'DD/MM/YYYY', true).isValid()) {
        invalidInputs.push('finalDate');
      }

    if (invalidInputs.length === 0) {
      this.setState({ invalidInputs: [] });
      return {
        "searchedText": this.state.searchedText,
        "fornecedorId": this.state.fornecedorId,
        "fornecedor": this.state.fornecedor,
        "solicitacaoId": this.state.solicitacaoId,
        "fundoId": this.state.fundoId,
        "fundo": this.state.fundo,
        "status": this.state.status,
        "tipoItensSolicitacao": this.state.tipoItensSolicitacao,
        "startDate": dayjs(this.state.startDate, 'DD/MM/YYYY', true).toJSON(),
        "finalDate": dayjs(this.state.finalDate, 'DD/MM/YYYY', true).toJSON(), 
        "valor": this.state.valor,
        "tipoSolicitacao": this.state.tipoSolicitacao
      };
    } else {
      this.setState({ invalidInputs: invalidInputs });
      return null;
    }
  }

  #onSearch = () => {
    if (this.timeoutID > 0) {
      clearTimeout(this.timeoutID);
    }

    this.timeoutID = setTimeout(() => {
      this.timeoutID = -1;
      const filter = this.#getFilter();
      if (filter) {
        this.props.onSearch?.(filter);
      }
    }, 400);
  }

  #onDownload = () => {
    const filter = this.#getFilter();
    if (filter) {
      this.props.onDownload?.(filter);
    }
  }

  #searchFornecedores = async (text) => {
    const fornecedores = await fornecedoresController.searchFornecedores(text);
    return fornecedores.items;
  }
}

class SearchPanelPortrait extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopOverOpen: false
    }
  }

  render() {
    return (
      <Popover
        isOpen={this.state.isPopOverOpen}
        onOpen={() => this.setState({ isPopOverOpen: true })}
        onClose={() => this.setState({ isPopOverOpen: false })}
        trigger={(triggerProps) => {
          return (
            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={FontAwesome5} name="search" size="4" />}
              {...triggerProps}
            >
              Pesquisar
            </Button>
          )
        }}>
        <Popover.Content>
          <Popover.Arrow />
          <Popover.CloseButton />
          <Popover.Header>Pesquisar solicitações</Popover.Header>
          <Popover.Body height={500} >
            <ScrollView width={300}>
              <SearchPanelBody {...this.props} />
            </ScrollView>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    );
  }
}

export default function SearchPanel(props) {
  return (
    <ResponsiveComponentSelector
      portraitComponent={
        <SearchPanelPortrait {...props} />
      }
      landscapeComponent={
        <SearchPanelBody {...props} />
      }
    />
  );
}