import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Spacer,
  HStack,
  Icon,
  FormControl,
  Select,
  Spinner,
  Radio,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { fornecedoresController } from "../controllers/FornecedoresController";
import { constsController } from "../controllers/ConstsController"
import MaskedInput from "../components/MaskedInput";
import { authController } from "../controllers/AuthController";
import { ResponsiveStack, ResponsiveFormControl } from "../components/ResponsiveUtils";

class ViewFornecedor extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewFornecedor = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("FornecedoresPolicies.Criar") || permicoes.includes("FornecedoresPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      razaoSocial: '',
      cnpj: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      cep: '',
      cidade: '',
      uf: '',
      bairro: '',
      logradouro: '',
      numero: '',
      complemento: '',
      foneFax: '',
      eMail: '',
      representanteLegalCpf: '',
      representanteLegalRg: '',
      representanteLegalNome: '',
      dadosBancariosConta: '',
      dadosBancariosAgencia: '',
      dadosBancariosBanco: '',
      dadosBancariosCpfTitularConta: '',
      tipoCpfCnpjFornecedor: 'cpf',
      tipoCpfCnpjRepresentante: 'cpf',
      invalidInputs: []
    }
  }


  componentDidMount() {
    this.loadFornecedor();
  }

  async loadFornecedor() {
    if (!this.creatingNewFornecedor) {
      const fornecedor = await fornecedoresController.getFornecedor(this.props.params.idFornecedor);
      this.setState({
        id: fornecedor.id,
        razaoSocial: fornecedor.razaoSocial,
        cnpj: fornecedor.cnpj ?? '',
        inscricaoMunicipal: fornecedor.inscricaoMunicipal ?? '',
        inscricaoEstadual: fornecedor.inscricaoEstadual ?? '',
        cep: fornecedor.endereco?.cep ?? '',
        cidade: fornecedor.endereco?.cidade ?? '',
        uf: fornecedor.endereco?.uf ?? '',
        bairro: fornecedor.endereco?.bairro ?? '',
        logradouro: fornecedor.endereco?.logradouro ?? '',
        numero: fornecedor.endereco?.numero ?? '',
        complemento: fornecedor.endereco?.complemento ?? '',
        foneFax: fornecedor.foneFax ?? '',
        eMail: fornecedor.eMail ?? '',
        representanteLegalCpf: fornecedor.representanteLegal?.cpf ?? '',
        representanteLegalRg: fornecedor.representanteLegal?.rg ?? '',
        representanteLegalNome: fornecedor.representanteLegal?.nome ?? '',
        dadosBancariosConta: fornecedor.dadosBancarios?.conta ?? '',
        dadosBancariosAgencia: fornecedor.dadosBancarios?.agencia ?? '',
        dadosBancariosBanco: fornecedor.dadosBancarios?.banco ?? '',
        dadosBancariosCpfTitularConta: fornecedor.dadosBancarios?.cpfTitular ?? '',
        tipoCpfCnpjFornecedor: (fornecedor.cnpj?.length > 14 ? 'cnpj' : 'cpf'),
        tipoCpfCnpjRepresentante: (fornecedor.representanteLegal?.cpf?.length > 14 ? 'cnpj' : 'cpf'),
      });
      this.forceUpdate();
    }
  }

  render() {
    if (!this.creatingNewFornecedor && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} space={2} marginRight={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewFornecedor ? 'Cadastrar novo Fornecedor' :
                this.viewOnly ? 'Visualizar Fornecedor' : 'Alterar Fornecedor'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewFornecedores'); }}>
            Voltar
          </Button>
        </HStack>
        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={0.5} isInvalid={this.state.invalidInputs.indexOf('razaoSocial') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Razão Social</FormControl.Label>
            <Input defaultValue={this.state.razaoSocial} onChangeText={(text) => this.setState({ razaoSocial: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a razão social</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.25} isInvalid={this.state.invalidInputs.indexOf('cnpj') !== -1} isDisabled={this.viewOnly}>
            <Radio.Group
              value={this.state.tipoCpfCnpjFornecedor}
              onChange={value => this.setState({ tipoCpfCnpjFornecedor: value, cnpj: '' })}
              flexDirection="row"
            >
              <Radio value="cpf" size="sm" marginX={2}>CPF</Radio>
              <Radio value="cnpj" size="sm">CNPJ</Radio>
            </Radio.Group>
            <MaskedInput
              mask={(this.state.tipoCpfCnpjFornecedor === 'cnpj' ? "99.999.999/9999-99" : "999.999.999-99")}
              value={this.state.cnpj}
              placeholder={(this.state.tipoCpfCnpjFornecedor === 'cnpj' ? "00.000.000/0000-00" : "000.000.000-00")}
              onChangeMaskedText={(text) => this.setState({ cnpj: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o CNPJ</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.25} isInvalid={this.state.invalidInputs.indexOf('inscricaoEstadual') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Inscrição estadual</FormControl.Label>
            <Input defaultValue={this.state.inscricaoEstadual} onChangeText={(text) => this.setState({ inscricaoEstadual: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a inscrição</FormControl.ErrorMessage>
          </ResponsiveFormControl>

          <ResponsiveFormControl flex={0.25} isInvalid={this.state.invalidInputs.indexOf('inscricaoMunicipal') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Inscrição municipal</FormControl.Label>
            <Input defaultValue={this.state.inscricaoMunicipal} onChangeText={(text) => this.setState({ inscricaoMunicipal: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a inscrição</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <Heading size="sm">Endereço</Heading>
        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={0.20} isInvalid={this.state.invalidInputs.indexOf('cep') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">CEP</FormControl.Label>
            <MaskedInput
              mask="99999-999"
              placeholder="00000-000"
              value={this.state.cep}
              onChangeMaskedText={(text) => this.setState({ cep: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o CEP</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.5} isInvalid={this.state.invalidInputs.indexOf('cidade') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Cidade</FormControl.Label>
            <Input defaultValue={this.state.cidade} onChangeText={(text) => this.setState({ cidade: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a cidade</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.20} isInvalid={this.state.invalidInputs.indexOf('uf') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">UF</FormControl.Label>
            <Select
              defaultValue={this.state.uf?.toString() ?? ''}
              onValueChange={(selectedItem) => this.setState({ uf: selectedItem })}
              placeholder="Escolher UF"
              key={'uf' + this.state.uf}
            >
              {
                Object.entries(constsController.UFs).map(([id, desc]) => {
                  return <Select.Item label={desc} value={id} key={id} />;
                })
              }
            </Select>
            <FormControl.ErrorMessage marginTop="0">Informe a UF</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.35} isInvalid={this.state.invalidInputs.indexOf('bairro') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Bairro</FormControl.Label>
            <Input defaultValue={this.state.bairro} onChangeText={(text) => this.setState({ bairro: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o bairro</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('logradouro') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Logradouro</FormControl.Label>
            <Input defaultValue={this.state.logradouro} onChangeText={(text) => this.setState({ logradouro: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o logradouro</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('numero') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Número</FormControl.Label>
            <Input defaultValue={this.state.numero} onChangeText={(text) => this.setState({ numero: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o numero</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Complemento</FormControl.Label>
            <Input defaultValue={this.state.complemento} onChangeText={(text) => this.setState({ complemento: text })} />
          </ResponsiveFormControl>
        </ResponsiveStack>

        <Heading size="sm">Contato</Heading>
        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={0.25} isInvalid={this.state.invalidInputs.indexOf('foneFax') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Telefone</FormControl.Label>
            <MaskedInput
              mask="(99) 9 9999-9999"
              placeholder="(00) 0 0000-0000"
              value={this.state.foneFax}
              onChangeMaskedText={(text) => this.setState({ foneFax: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o telefone</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={0.5} isInvalid={this.state.invalidInputs.indexOf('eMail') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Email</FormControl.Label>
            <Input defaultValue={this.state.eMail} onChangeText={(text) => this.setState({ eMail: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o email</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <Spacer />
        </ResponsiveStack>

        <Heading size="sm">Representante Legal</Heading>
        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('representanteLegalNome') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Nome</FormControl.Label>
            <Input defaultValue={this.state.representanteLegalNome} onChangeText={(text) => this.setState({ representanteLegalNome: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o nome</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('representanteLegalCpf') !== -1} isDisabled={this.viewOnly}>
            <Radio.Group
              value={this.state.tipoCpfCnpjRepresentante}
              onChange={value => this.setState({ tipoCpfCnpjRepresentante: value, representanteLegalCpf: '' })}
              flexDirection="row"
            >
              <Radio value="cpf" size="sm" marginX={2}>CPF</Radio>
              <Radio value="cnpj" size="sm">CNPJ</Radio>
            </Radio.Group>
            <MaskedInput
              mask={(this.state.tipoCpfCnpjRepresentante === 'cnpj' ? "99.999.999/9999-99" : "999.999.999-99")}
              value={this.state.representanteLegalCpf}
              placeholder={(this.state.tipoCpfCnpjRepresentante === 'cnpj' ? "00.000.000/0000-00" : "000.000.000-00")}
              onChangeMaskedText={(text) => this.setState({ representanteLegalCpf: text })}
            />
            <FormControl.ErrorMessage marginTop="0">Informe o CNPJ</FormControl.ErrorMessage>
          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('representanteLegalRg') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">RG</FormControl.Label>
            <Input defaultValue={this.state.representanteLegalRg} onChangeText={(text) => this.setState({ representanteLegalRg: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o RG</FormControl.ErrorMessage>
          </ResponsiveFormControl>
        </ResponsiveStack>

        <Heading size="sm">Dados bancários</Heading>
        <ResponsiveStack space={4}>
          <ResponsiveFormControl flex={1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Conta</FormControl.Label>
            <Input defaultValue={this.state.dadosBancariosConta} onChangeText={(text) => this.setState({ dadosBancariosConta: text })} />

          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Agência</FormControl.Label>
            <Input defaultValue={this.state.dadosBancariosAgencia} onChangeText={(text) => this.setState({ dadosBancariosAgencia: text })} />

          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Banco</FormControl.Label>
            <Input defaultValue={this.state.dadosBancariosBanco} onChangeText={(text) => this.setState({ dadosBancariosBanco: text })} />

          </ResponsiveFormControl>
          <ResponsiveFormControl flex={1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">CPF do Titular</FormControl.Label>
            <MaskedInput
              mask="999.999.999-99"
              placeholder="000.000.000-00"
              value={this.state.dadosBancariosCpfTitularConta}
              onChangeMaskedText={(text) => this.setState({ dadosBancariosCpfTitularConta: text })}
            />

          </ResponsiveFormControl>
        </ResponsiveStack>

        <HStack space={2}>
          <Spacer />
          <Button
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            onPress={() => { this.#onSave(); }}
            isDisabled={this.viewOnly}
          >
            {this.creatingNewFornecedor ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>
      </VStack>
    );
  }

  #validate = () => {
    return Object.getOwnPropertyNames(this.state).filter((propertyName) => {
      if (['razaoSocial', 'cnpj'].indexOf(propertyName) !== -1) {
        switch (typeof this.state[propertyName]) {
          case 'string':
            return this.state[propertyName].length === 0;

          case 'number':
            return this.state[propertyName] === 0;

          default:
            return true;
        }
      }

      return false;
    });
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const fornecedor = {
        "razaoSocial": this.state.razaoSocial,
        "cnpj": this.state.cnpj,
        "inscricaoEstadual": this.state.inscricaoEstadual,
        "inscricaoMunicipal": this.state.inscricaoMunicipal,
        "endereco": {
          "cep": this.state.cep,
          "bairro": this.state.bairro,
          "logradouro": this.state.logradouro,
          "numero": this.state.numero,
          "complemento": this.state.complemento,
          "cidade": this.state.cidade,
          "uf": this.state.uf
        },
        "foneFax": this.state.foneFax,
        "eMail": this.state.eMail,
        "representanteLegal": {
          "nome": this.state.representanteLegalNome,
          "cpf": this.state.representanteLegalCpf,
          "rg": this.state.representanteLegalRg
        },
        "dadosBancarios": {
          "conta": this.state.dadosBancariosConta,
          "agencia": this.state.dadosBancariosAgencia,
          "banco": this.state.dadosBancariosBanco,
          "cpfTitular": this.state.dadosBancariosCpfTitularConta
        }
      };

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewFornecedor) {
        success = await fornecedoresController.insertFornecedor(fornecedor);
      } else {
        success = await fornecedoresController.updateFornecedor(fornecedor, this.props.params.idFornecedor);
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewFornecedores');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default ViewFornecedor;