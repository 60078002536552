import React from "react";

import {
  Center,
  Text,
  VStack,
  Input,
  Button,
  Heading,
  Modal,
  Icon,
  Spacer,
  HStack,
  FormControl,
  Spinner
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { authController } from "../controllers/AuthController";
import { fontesRecursosController } from "../controllers/FontesRecursosController";
import { ResponsiveStack } from "../components/ResponsiveUtils";

class ViewFonteRecurso extends React.Component {

  constructor(props) {
    super(props);
    this.creatingNewFonteRecurso = !!this.props.params?.insertion;

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.viewOnly = !(permicoes.includes("FontesRecursosPolicies.Criar") || permicoes.includes("FontesRecursosPolicies.Atualizar"));

    this.state = {
      await: false,
      id: 0,
      nome: '',
      codigo: '',
      dotacaoOrcamentaria: '',
      origemRecurso: '',
      invalidInputs: [
      ]
    }
  }

  componentDidMount() {
    this.loadFonteDeRecurso();
  }

  async loadFonteDeRecurso() {
    if (!this.creatingNewFonteRecurso) {
      const fonteRecurso = await fontesRecursosController.getFonteRecurso(this.props.params.idFonteRecurso);
      this.setState({
        id: fonteRecurso.id,
        nome: fonteRecurso.nome,
        codigo: fonteRecurso.codigo ?? '',
        dotacaoOrcamentaria: fonteRecurso.dotacaoOrcamentaria ?? '',
        origemRecurso: fonteRecurso.origemRecurso ?? ''
      });
    }
  }

  render() {
    if (!this.creatingNewFonteRecurso && this.state.id === 0) {
      return (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <VStack flex={1} space={2}>
        <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
          <Heading color="white">
            {
              this.creatingNewFonteRecurso ? 'Cadastrar nova Dotação Orçamentária' :
                this.viewOnly ? 'Visualizar Dotação Orçamentária' : 'Alterar Dotação Orçamentária'
            }
          </Heading>
          <Spacer />
          <Button
            my={1}
            leftIcon={<Icon as={FontAwesome5} name="arrow-left" size="4" />}
            onPress={() => { this.props.viewManager.showView('ViewFontesRecursos'); }}>
            Voltar
          </Button>
        </HStack>
        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <ResponsiveStack space="2">
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('nome') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Projeto/Atividade</FormControl.Label>
            <Input defaultValue={this.state.nome} onChangeText={(text) => this.setState({ nome: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o nome</FormControl.ErrorMessage>
          </FormControl>
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('codigo') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Código Orçamentária</FormControl.Label>
            <Input defaultValue={this.state.codigo} onChangeText={(text) => this.setState({ codigo: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe o código</FormControl.ErrorMessage>
          </FormControl>
        </ResponsiveStack>
        <ResponsiveStack space="2">
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('dotacaoOrcamentaria') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Unidade Orçamentária:</FormControl.Label>
            <Input defaultValue={this.state.dotacaoOrcamentaria} onChangeText={(text) => this.setState({ dotacaoOrcamentaria: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a Unidade Orçamentária:</FormControl.ErrorMessage>
          </FormControl>
          <FormControl flex={1} isInvalid={this.state.invalidInputs.indexOf('origemRecurso') !== -1} isDisabled={this.viewOnly}>
            <FormControl.Label marginBottom="0">Origem Recurso</FormControl.Label>
            <Input defaultValue={this.state.origemRecurso} onChangeText={(text) => this.setState({ origemRecurso: text })} />
            <FormControl.ErrorMessage marginTop="0">Informe a Origem Recurso</FormControl.ErrorMessage>
          </FormControl>
        </ResponsiveStack>
        <HStack my="2" space="2">
          <Spacer />
          <Button
            leftIcon={<Icon as={FontAwesome5} name="check" size="4" />}
            onPress={() => { this.#onSave() }}
            isDisabled={this.viewOnly}
          >
            {this.creatingNewFonteRecurso ? 'Cadastrar' : 'Alterar'}
          </Button>
        </HStack>
      </VStack>
    );
  }

  #validate = () => {

    return Object.getOwnPropertyNames(this.state).filter((propertyName) => {
      if (['id', 'codigo', 'dotacaoOrcamentaria', 'origemRecurso', 'invalidInputs', 'await'].indexOf(propertyName) !== -1)
        return false;

      switch (typeof this.state[propertyName]) {
        case 'string':
          return this.state[propertyName].length === 0;

        case 'number':
          return this.state[propertyName] === 0;

        default:
          return true;
      }
    });
  }

  #onSave = async () => {
    const invalidInputs = this.#validate();
    if (invalidInputs.length === 0) {
      const fonteRecurso = {
        "nome": this.state.nome,
        "codigo": this.state.codigo,
        "dotacaoOrcamentaria": this.state.dotacaoOrcamentaria,
        "origemRecurso": this.state.origemRecurso,
      }

      let success = false;
      this.setState({ await: true });
      if (this.creatingNewFonteRecurso) {
        success = await fontesRecursosController.insertFonteRecurso(fonteRecurso);
      } else {
        success = await fontesRecursosController.updateFonteRecurso(fonteRecurso, this.props.params.idFonteRecurso);
      }
      this.setState({ await: false });

      if (success)
        this.props.viewManager.showView('ViewFontesRecursos');
    } else {
      this.setState({ invalidInputs: invalidInputs });
    }
  }
}

export default ViewFonteRecurso;