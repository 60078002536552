import { ENDPOINT } from "./ApiDefs";
import { authController } from './AuthController';
import { notificationController } from "./NotificationController";

class ContratosController {
  getContrato = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Contratos/' + id.toString() + '?includeItens=true', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao carregar o contrato', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao carregar o contrato', error);
      return null;
    }
  }

  searchContratos = async (searchOptions) => {
    try {
      let query = ENDPOINT + '/api/Contratos?countTotal=true'
      const filter = this.#createFilter(searchOptions);

      query = query + filter;

      const response = await fetch(query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        }
      });
      if (response.ok) {
        const contratos = await response.json();

        const newItems = contratos.items.map((contrato) => {
          return { ...contrato, nome: contrato.numero + '-' + contrato.descricao }
        });

        if (searchOptions.semSaldo){
          contratos.items =newItems.filter((c) => c.saldo > 0);
        } else {
          contratos.items = newItems;
        }

        return contratos;
      } else {
        notificationController.publishError('Falha ao pesquisar os contratos', await response.text());
        return [];
      }
    } catch (error) {
      notificationController.publishError('Falha ao pesquisar os contratos', error);
      return [];
    }
  }

  insertContrato = async (contrato) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Contratos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(contrato)
      });
      if (response.ok) {
        return await response.json();
      } else {
        notificationController.publishError('Falha ao inserir o contrato', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao inserir o contrato', error);
      return false;
    }
  }

  updateContrato = async (contrato, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Contratos/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify(contrato)
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o contrato', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o contrato', error);
      return false;
    }
  }

  updateSaldoContrato = async (saldo, id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Contratos/atualiza-saldo/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
        body: JSON.stringify({ saldoFinal: saldo })
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao atualizar o saldo do contrato', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao atualizar o saldo do contrato', error);
      return false;
    }
  }

  deleteContrato = async (id) => {
    try {
      const response = await fetch(ENDPOINT + '/api/Contratos/' + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return true;
      } else {
        notificationController.publishError('Falha ao excluir o contrato', await response.text());
        return false;
      }
    } catch (error) {
      notificationController.publishError('Falha ao excluir o contrato', error);
      return false;
    }
  }

  #createFilter = (options) => {
      var query = '';
      if (options.text) {
        query += '&q=' + options.text;
      }
      if (options.idFornecedor) {
        query += '&fornecedorId=' + options.idFornecedor;
      }
      if (options.idFundo) {
        query += '&fundoId=' + options.idFundo;
      }
      if (options.semVencidos) {
        query += '&semVencidos=true'
      }
      if (options.startDate) {
        query += '&dataInicial=' + options.startDate;
      }
      if (options.finallDate) {
        query += '&dataFinal=' + options.finalDate;
      }

      if (options.page) {
        const skip = (options.page - 1) * options.itensPerPage;
        query += '&skip=' + skip.toString() + '&limit=' + options.itensPerPage.toString()
      } else {
        query += '&limit=50';
      }

      if (options.length > 0) {
        query = '?' + query.substring(1);
      }

      return query;
    }
  
  downloadContratos = async (options) => {
    try {
      let filter = this.#createFilter(options);
  
      const response = await fetch(ENDPOINT + '/api/Contratos/gerar-relatorio-lista-contrato-pdf?' + filter, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authController.getUserToken()
        },
      });
      if (response.ok) {
        return await response.blob();
      } else {
        notificationController.publishError('Falha ao baixar relatório de solicitações', await response.text());
        return null;
      }
    } catch (error) {
      notificationController.publishError('Falha ao baixar relatório de solicitações', error);
      return null;
    }
  }

}

export const contratosController = new ContratosController();
