import React from "react";

import {
  Center,
  Text,
  VStack,
  Button,
  Heading,
  Spacer,
  HStack,
  Icon,
  Spinner,
  Modal,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

import { GridView, ResponsiveGridItem } from '../components/GridView';
import { ListNavigator } from '../components/ListNavigator';
import { licitacoesController } from "../controllers/LicitacoesController";
import ActionButton from "../components/ActionButton";
import { authController } from "../controllers/AuthController";
import ConfirmModal from "../components/ConfirmModal";
import SearchPanelLicitacoes from "./ViewLicitacoesSearchPanel";
import { TooltipPressable } from "../components/TooltipPressable";
import PDFViewer from "../components/PDFViewer";

const NAVIGATOR_ITENS_PER_PAGE = 15;


  
const ActionButtons = (props) => {
  return (
    <Center padding="0">
      <HStack space="1">
        <ActionButton
          isDisabled={props.isDisabledImprimir}
          onPress={props.onPrint}
          iconName="print"
          tooltip="Imprimir"
        />
        <ActionButton
          isDisabled={props.isDisabledDelete}
          onPress={props.onDelete}
          iconName="trash-alt"
          tooltip="Excluir"
        />
      </HStack>
    </Center>
  );
}


class ViewLicitacoes extends React.Component {

  constructor(props) {
    super(props);

    let permicoes = JSON.parse(authController.getCurrentUser().perfil);
    this.notCriar = !(permicoes.includes("LicitacoesPolicies.Criar"));
    this.notRemover = !(permicoes.includes("LicitacoesPolicies.Remover"));

    this.state = {
      await: false,
      searching: false,
      currentPage: 1,
      countItens: 0,
      licitacaoForDeletion: 0,
      licitacoes: [],
      urlSource: null,
      waitMessage: '',
    }
    if (localStorage.getItem('page') !== 'licitacao') {
      localStorage.removeItem('filtro');
      localStorage.removeItem('currentPage');
      localStorage.setItem('page', 'licitacao');
    }
    else {
      if (localStorage.getItem('currentPage'))
        this.state.currentPage = localStorage.getItem('currentPage');
      else  
        this.state.currentPage = 1;
    }
  }

  componentDidMount() {
    this.#loadLicitacoes();
  }

  render() {
    return (
      <Center flex={1}>
        <VStack flex={1} width={'100%'} space={2}>
          <HStack paddingX={2} borderTopRadius={4} backgroundColor="primary.700">
            <Heading color="white">Licitações</Heading>
            <Spacer />
            <Button
              isDisabled={this.notCriar}
              my="1"
              leftIcon={<Icon as={FontAwesome5} name="plus" size="4" />}
              onPress={() => { this.props.viewManager.showView('ViewLicitacao', { insertion: true }) }}>
              Adicionar
            </Button>
          </HStack>

          <SearchPanelLicitacoes
            onSearch={(options) => {
              this.state.currentPage = 1;
              this.#searchLicitacoes(options);
            }}
            onDownload={this.#downloadLicitacoes}
          />

          <ListNavigator
            key={'ListNavigator' + this.state.currentPage}
            page={this.state.currentPage}
            countItens={this.state.countItens}
            itensPerPage={NAVIGATOR_ITENS_PER_PAGE}
            onChangePage={(page) => {
              this.setState({ currentPage: page }, () => this.#loadLicitacoes());
            }}
          />

          <GridView
            borderRadius="4"
            data={this.state.searching ? [] : this.state.licitacoes}
            keyExtractor={(item) => { return item.id.toString(); }}
            columnCaptions={[
              "Descrição",
              "Fornecedor",
              "Data",
              "Valor total",
              "",
            ]}
            columnDimensions={[0.35, 0.3, 0.15, 0.15, 0.05]}
            ListEmptyComponent={
              (
                this.state.searching &&
                <Spinner marginY={3} size="lg" />
              )
              ||
              (
                <Center>
                  <Text>Sem resultados</Text>
                </Center>
              )
            }
            renderGridItem={(item, index, separators, props) => {
              return (
                <TooltipPressable
                  onPress={() => { this.props.viewManager.showView('ViewLicitacao', { idLicitacao: item.id }) }}>
                  {
                    ({ isHovered, isFocused, isPressed }) => {
                      return (
                        <ResponsiveGridItem flex="1"
                          tooltipVisibool={true}
                          values={[
                            item.descricao,
                            item.fornecedor,
                            new Date(item.data).toLocaleString(),
                            new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'  }).format(item.valorTotal),
                            <ActionButtons
                              isDisabledVizualizar={false}
                              isDisabledDelete={this.notRemover}
                              onDelete={() => {() => this.setState({ licitacaoForDeletion: item.id })}}
                              onPrint={() => { this.#downloadLicitacao(item.id) }}
                            />,
                          ]}
                          backgroundColor={isPressed ? "primary.200" : isHovered ? "primary.100" : (index % 2 === 0) ? "coolGray.100" : null}
                          width="100%"
                          gridProps={props}
                          dimensions={props.columnDimensions}
                        />
                      )
                    }
                  }
                </TooltipPressable>
              )
            }}
          />
        </VStack>

        <Modal
          isOpen={this.state.await}
          onClose={() => this.setState({ await: false })}
          size="sm"
        >
          <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>Aguarde...</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <ConfirmModal
          isOpen={this.state.licitacaoForDeletion > 0}
          onClose={() => this.setState({ licitacaoForDeletion: 0 })}
          onConfirm={() => this.#deleteLicitacao(this.state.licitacaoForDeletion)}
          headerText="Excluir licitação"
          bodyText="Atenção! A licitação será excluída. Confirma esta ação?"
          buttonConfirmText="Excluir"
        />

        <PDFViewer
          isOpen={!!this.state.urlSource}
          onClose={() => this.setState({ urlSource: null })}
          urlSource={this.state.urlSource}
        />
   
        <Modal
          isOpen={this.state.waitMessage.length > 0}
          onClose={() => this.setState({ waitMessage: '' })}
          size="sm"
        >
        <Modal.Content>
            <Modal.Body>
              <Center>
                <Text marginY={2}>{this.state.waitMessage}</Text>
              </Center>
              <Spinner size="lg"></Spinner>
            </Modal.Body>
          </Modal.Content>
        </Modal>

      </Center>
    );
  }

  #loadLicitacoes = () => {
    if (localStorage.getItem('page') === 'licitacao') {
      const filtroStr = localStorage.getItem('filtro');
      if (filtroStr) {
        const filtro = JSON.parse(filtroStr);
        this.#searchLicitacoes(filtro);
      } else
        this.#searchLicitacoes({});
    }
  }

  #searchLicitacoes = async (searchOptions) => {
    this.setState({ searching: true });
    localStorage.setItem('filtro', JSON.stringify(searchOptions));
    localStorage.setItem('currentPage', this.state.currentPage);
    const licitacoes = await licitacoesController.searchLicitacoes(
      searchOptions, this.state.currentPage, NAVIGATOR_ITENS_PER_PAGE);
    this.setState({ searching: false, licitacoes: licitacoes.items, countItens: licitacoes.totalCount });
  }

  #deleteLicitacao = async (id) => {
    this.setState({ licitacaoForDeletion: 0 });
    this.setState({ await: true });
    if (await licitacoesController.deleteLicitacao(id)) {
      this.#searchLicitacoes({});
    }
    this.setState({ await: false });
  }
  
  #downloadLicitacoes = async (options) => {
    this.setState({ waitMessage: 'Gerando relatório. Aguarde...' });
    const pdf = await licitacoesController.downloadLicitacoes(options);
    this.setState({ waitMessage: '', urlSource: (pdf) ? URL.createObjectURL(pdf) : null })
  }

  #downloadLicitacao = async (id) => {
    this.setState({ waitMessage: 'Gerando relatório. Aguarde...', solicitacaoForDeletion: 0 });
    const pdf = await licitacoesController.downloadLicitacao(id)
    if (pdf)
      this.#loadLicitacoes();
    this.setState({ waitMessage: '', urlSource: (pdf) ? URL.createObjectURL(pdf) : null });
  }
  
}

export default ViewLicitacoes;
